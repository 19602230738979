@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
@import "node_modules/bootstrap/scss/bootstrap";
// @import './theme/custom-theme.scss';
// @import './theme/buttons.scss';
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

.report-item-link {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--primary-color);
  cursor: pointer;
  &:hover {
    color: var(--text-color);
  }
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.shadow {
  box-shadow: 1px 0px 20px rgb(0 0 0 / 8%);
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-table {
  width: 100%;
}

form {
  h3 {
    font-weight: 600 !important;
  }
}

h1 {
  margin: 0;
  color: #2a2a2d;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0;
  line-height: 40px;
  letter-spacing: 0.7px;
}

.w50 {
  width: 50%;
}

.w35 {
  width: 35%;
}

.w100 {
  width: 35%;
}

.mat-card-sm {
  padding: 5px !important;
}

.admin-container {
  width: 100%;
  height: 100vh;
  border: 1px solid rgba(24, 23, 23, 0.5);

  .admin-sidenav-content {
    // display: flex;
    min-height: 100%;
    box-shadow: 1px 0px 20px rgb(0 0 0 / 8%);
    .admin-toolbar {
      background: #ccdfee !important;
      color: rgba(0, 0, 0, 0.87);
      .dropdown-menu {
        padding: 0;
        min-width: 20rem;
      }
      .dropdown-toggle::after {
        display: none;
      }

      .profile-menu {
        li {
          cursor: pointer;
        }
      }
    }
  }

  .admin-sidenav {
    padding: 15px 5px;
  }

  .sidenav-min {
    width: 30px;
  }

  .admin-logo {
    .title {
      font-weight: 700;
      font-size: 1.5rem;
    }
  }
}

.mat-menu-item {
  line-height: 38px !important;
  height: 38px !important;
}

.clabel {
  margin: 0 !important;
  color: #919599 !important;
  font-weight: 400;
  font-size: 90%;
}

.clabel2 {
  margin: 0 !important;
  color: #070707cc !important;
  font-weight: 600;
  font-size: 90%;
}

.cvalue {
  color: #343638 !important;
  font-weight: 700;
  line-height: 1.2;
  font-size: 0.875rem;
}

.text-align-end {
  text-align: end;
}

.break-word {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hand {
  cursor: pointer !important;
}

.dp-max {
  max-width: 300px;
}

.max-w-93 {
  max-width: 93vw;
}

.max-w-92 {
  max-width: 92vw;
}

.max-w-87 {
  max-width: 87vw;
}

.max-w-86 {
  max-width: 86vw;
}

.de-copy-row-icon {
  color: var(--badge-warn-bg) !important;
  font-size: 4rem !important;
}

.font-bolder {
  font-weight: 900;
}

p-inputnumber,
p-inputnumber > span.p-inputnumber {
  flex-grow: 1;
}
